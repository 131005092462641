@mixin snif-btn-icon {
    display: inline-block;
    height: 1em;
    width: 1em;
}

@mixin snif-btn-size($size: 'md') {
    @include snif-typography('btn-#{$size}');
}

@mixin snif-btn {
    @include font-weight('semibold');
    @include snif-typography('btn-md');
    box-sizing: border-box;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 12px 24px;
    text-decoration: none !important;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 200ms;

    &:not(:disabled),
    &:not(.disabled) {
        cursor: pointer;
    }

    &:disabled,
    &.disabled {
        cursor: default;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: none;
    }

    &__icon {
        @include snif-btn-icon;
    }
    &__icon_left {
        margin-right: 8px;
    }
    &__icon_right {
        margin-left: 8px;
    }
}

@mixin snif-btn-variant($variant: 'primary') {
    $values: map-get($snif-btn-types, $variant);

    color: map-get($values, 'color-default');
    background-color: map-get($values, 'bg-default');
    border-width: map-get($values, 'border-width');
    border-color: map-get($values, 'border-default');

    &.hover,
    &:hover {
        color: map-get($values, 'color-hover');
        background-color: map-get($values, 'bg-hover');
        border-color: map-get($values, 'border-hover');
    }

    &.active,
    &:active {
        color: map-get($values, 'color-active');
        background-color: map-get($values, 'bg-active');
        border-color: map-get($values, 'border-active');
    }

    &:not(:disabled),
    &:not(.disabled) {
        &:focus,
        &.focus {
            color: map-get($values, 'color-focus');
            background-color: map-get($values, 'bg-focus');
            border-color: map-get($values, 'border-focus');
        }
    }

    &:disabled,
    &.disabled {
        color: map-get($values, 'color-disabled');
        background-color: map-get($values, 'bg-disabled');
        border-color: map-get($values, 'border-disabled');
    }
}
