@import './bootstrap/variables';
@import './variables';
@import './font-mixin';
@import './typography';
@import './palette';
@import './buttons';
@import './datepicker.scss';

html {
    font-size: $root-font-size;
}

body.modal-open {
    padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 600;
    font-family: 'Work Sans';
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0;
    font-weight: 400;
    font-family: 'Work Sans';
    font-weight: normal;
}

h1,
.h1 {
    font-size: 36px;
    line-height: 48px;
}

h2,
.h2 {
    font-size: 32px;
    line-height: 40px;
}

h3,
.h3 {
    font-size: 24px;
    line-height: 32px;
}

h4,
.h4 {
    font-size: 18px;
    line-height: 24px;
}

@media #{$max-width-mobile} {
    h1,
    .h1 {
        font-size: 32px;
        line-height: 40px;
    }

    h2,
    .h2 {
        font-size: 24px;
        line-height: 32px;
    }

    h3,
    .h3 {
        font-size: 18px;
        line-height: 24px;
    }

    h4,
    .h4 {
        font-size: 16px;
        line-height: 24px;
    }
}

p {
    margin-bottom: 0;
}

a {
    text-decoration: none;
}

hr {
    margin: $hr-margin 0;
    opacity: 0.1;
}

.px-05 {
    padding-left: snif-grid(0.5);
    padding-right: snif-grid(0.5);
}

.py-05 {
    padding-top: snif-grid(0.5);
    padding-bottom: snif-grid(0.5);
}

.alert-block {
    position: relative;
    top: 0;
}

@media screen and (max-width: $mobile-size) {
    hr {
        margin: $hr-mobile-margin 0;
    }

    .alert-block {
        position: relative;
        top: 70px;
    }
}

.modal-container {
    position: relative;
}

.modal-container .modal,
.modal-container .modal-backdrop {
    position: absolute;
}

.container {
    padding: 0;
}

.hiden {
    display: none;
}

.no-scroll {
    position: fixed;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: $gray-200;
    border-radius: 4px;
}

#sniff-app {
    #loading-container {
        height: 100vh;
        display: flex;
        justify-content: center;

        .spinner {
            background-image: url('./src/assets/svg/SvgSpinner.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.invite-tooltip {
    .invite-tooltip-popper {
        .invite-tooltip-inner {
            @include dates;
            background-color: $soft-gray;
            color: $dark-gray;
        }

        .invite-tooltip-arrow {
            &:before {
                border-top-color: $soft-gray;
            }
        }
    }
}

// ADDED GLOBAL CSS

.no-border {
    border: none !important;
}

.b-top {
    border-top: 1px solid $fog-gray;
}

.b-right {
    border-right: 1px solid $fog-gray;
}

.b-bot {
    border-bottom: 1px solid $fog-gray;
}

.b-left {
    border-left: 1px solid $fog-gray;
}

.c-pointer {
    cursor: pointer;
}

.c-md-default {
    @media #{$min-width-mobile} {
        cursor: default;
    }
}

.text-deco-none {
    text-decoration: none !important;
}

.text-underline {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}

.text-underline-dotted {
    text-decoration: underline !important;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}

.text-italic {
    font-style: italic;
}

.align-item-start {
    align-items: flex-start;
}

.align-item-end {
    align-items: flex-end;
}

.mt-05 {
    margin-top: snif-grid(0.5);
}

.mb-05 {
    margin-bottom: snif-grid(0.5);
}

.me-05 {
    margin-right: snif-grid(0.5);
}

.ms-05 {
    margin-left: snif-grid(0.5);
}

.pt-05 {
    padding-top: snif-grid(0.5);
}

.pb-05 {
    padding-bottom: snif-grid(0.5);
}

.pe-05 {
    padding-right: snif-grid(0.5);
}

.ps-05 {
    padding-left: snif-grid(0.5);
}

.p-15 {
    padding: snif-grid(1.5);
}

.text-pre {
    white-space: pre-wrap;
}

.rmc-picker {
    .rmc-picker-item.rmc-picker-item-selected {
        color: $dark-gray;
        font-size: 24px;
        font-weight: 500;
    }

    .rmc-picker-indicator {
        border: none;
    }
}

.text-first-cap::first-letter {
    text-transform: capitalize;
}

.desktop-container {
    @media #{$min-width-mobile} {
        width: 100%;
        max-width: 1248px;
        margin: 0 auto;
        padding: 0 snif-grid(3);
    }
}

.navbar-ssr {
    position: sticky;
    top: 0;
    z-index: 125;
}

.img-circle {
    border-radius: 50%;
}

#termly-code-snippet-support .t-consentPrompt {
    display: none !important;
}

@media #{$max-width-mobile} {
    .root-html-focused {
        body {
            overflow-y: hidden !important;
            width: 100vw !important;
            height: 100vh !important;
            height: 100dvh !important;

            // #sniff-app {
            //     height: 100vh;
            //     height: 100dvh;
            //     overflow-y: hidden;
            // }

            .mobile-listings-container {
                overflow: hidden !important;
                width: 100vw !important;
                height: calc(100vh - 72px) !important;
                height: calc(100dvh - 72px) !important;
            }
        }
    }
}

// Splide

.splide__arrow {
    background-color: $white;
    opacity: 0.6;
    transition: all 200ms;

    &:hover {
        opacity: 1;
    }

    svg {
        width: 12px;
        height: 12px;
    }
}
