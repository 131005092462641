@import './palette';
@import './functions';
@import './variables';

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: $light-gray;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: $snow-white;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: $light-gray;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: $white;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: $light-gray;
}

.react-datepicker-wrapper {
    display: inline-block;
}

.react-datepicker {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    background-color: $white;
    color: $black;
    border: 1px solid $light-gray;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 50%;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 50%;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 333;
}

.react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 10px;
}

.react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^='right'] {
    margin-left: 8px;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^='left'] {
    margin-right: 8px;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    position: relative;
    padding-top: 0.7em;
    font-weight: 500;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0;
    color: $black;
    font-size: 1.2em;
    font-weight: 500;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    text-align: center;
    cursor: pointer;
    position: absolute;
    width: 0;
    padding: 0;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: $light-gray;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
    border-right-color: $light-gray;
    cursor: default;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: $light-gray;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
    border-left-color: $light-gray;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: $light-gray;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: $light-gray;
}

.react-datepicker__month-container {
    float: left;
    width: inherit;
}

.react-datepicker__month {
    margin: 0.4em 0;
    text-align: center;
    font-weight: 700;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid $light-gray;
    width: 70px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid $light-gray;
    border-radius: 50%;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 30px;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 5px 10px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: $snow-white;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: map-get($map-blue, '600');
    color: $white;
    font-weight: 600;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: map-get($map-blue, '600');
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 50%;
    background-color: $snow-white;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-around;
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $black;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day {
    cursor: pointer;
    margin: 2px;
    width: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.9em;
}

.react-datepicker__day:hover {
    background-color: $snow-white;
    border-radius: 50%;
}

.react-datepicker__day--today {
    font-weight: 600;
    border: 1px solid $soft-gray;
    border-radius: 50%;
}

.react-datepicker__day--highlighted {
    border-radius: 50%;
    background-color: $green;
    color: $white;
}

.react-datepicker__day--highlighted:hover {
    background-color: $green;
}

.react-datepicker__day--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    border-radius: 50%;
    background-color: map-get($map-blue, '600');
    color: $white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
    background-color: map-get($map-blue, '700');
}

.react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: $blue;
    color: $white;
}

.react-datepicker__day--keyboard-selected:hover {
    background-color: map-get($map-blue, '700');
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(map-get($map-blue, '600'), 0.5);
}

.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: $snow-white;
    color: $black;
}

.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 50%;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: $light-gray;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: $white;
    position: absolute;
    width: 60%;
    left: 20%;
    top: 36px;
    z-index: 1;
    text-align: center;
    border-radius: snif-grid(0.5);
    border: 1px solid $fog-gray;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 24px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__month-option--selected_month,
.react-datepicker__year-option--selected_year {
    background-color: $blue;
    color: $white;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: $light-gray;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: $light-gray;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    display: none;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
}

.react-datepicker__close-icon::after {
    background-color: map-get($map-blue, '600');
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: $white;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
}

.react-datepicker__today-button {
    background: $snow-white;
    border-top: 1px solid $light-gray;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    font-weight: 500;
    font-size: 18px;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.2em;
    font-weight: 500;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: $light-gray;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: $light-gray;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: $light-gray;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: $light-gray;
    cursor: default;
}

.datepicker-controll {
    display: flex;
    .datepicker-button {
        width: 40px;
        height: 40px;
        margin-left: 20px;
        border: none;
        background: $white;
        background-image: url('./src/assets/svg/SvgCalendar.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .cross {
        background: url('./src/assets/svg/SvgCross.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        opacity: 0.3;
        margin-left: 5px;
    }
    .datepicker-controll__item {
        display: flex;
        align-items: center;
        .default {
            margin-top: 10px;
            font-size: $h6-size;
        }
    }
}
