@import './variables/buttons';
@import './mixins/buttons';

.snif-btn {
    @include snif-btn;
}

@each $size in $snif-btn-sizes {
    .snif-btn_#{$size} {
        @include snif-btn-size($size);
    }
}

@each $type, $values in $snif-btn-types {
    .snif-btn_#{$type} {
        @include snif-btn-variant($type);
    }
}
